import { Injectable } from '@angular/core';
import { ConfigService } from "./config.service";
import { Subject } from "rxjs";

import { WebSocketFeedback, WebSocketMessage } from "../models/webSocketMessage";
import { AiSendMessage } from "../models/aimes";
import { EventService } from "./event.service";
import { RouterService } from "./router.service";
import {VisibilityService} from "./visibility.service";
import {AnimationService} from "./animation.service";

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    private isConnected: boolean = false;
    private isRetrying: boolean = false;
    private retryNo: number = 0;
    private maxRetry: number = 10;
    private socket: WebSocket | null = null;
    private projectId: string | null = null;
    private anonymousId: string | null = null;
    private timeout: any | null = null;

    public message: Subject<WebSocketMessage> = new Subject<WebSocketMessage>();

    constructor(private config: ConfigService, private event: EventService, private visibility: VisibilityService, private animation: AnimationService) {
        (window as any).simulateDisconnect = this.simulateDisconnect.bind(this);
        (window as any).simulateError = this.simulateError.bind(this);
        (window as any).sendMessage = this.sendMessage.bind(this);
    }

    // getters
    public getConnectionStatus(): boolean {
        return this.isConnected;
    }

    public getWebsocketHost(): string {
        const host = this.config.getOrchestrationHost();
        const scheme = this.config.getWssScheme();
        const projectId = this.config.getAgentId();
        const anonymousId = this.config.getAnonymousId();
        const agentId = this.config.getAgentId();

        return `${scheme}://${host}/socket/project/${projectId}/agent/${agentId}/${anonymousId}`;
    }

    // setters
    public setProjectId(id: string | null): void {
        this.projectId = id;
    }

    public setAnonymousId(id: string): void {
        this.anonymousId = id;
    }

    public setConnectionStatus(status: boolean): void {
        this.isConnected = status;
    }

    // Initialize connection
    public async initConnection(): Promise<void> {
        this.setProjectId(this.config.getAgentId());
        this.setAnonymousId(this.config.getAnonymousId());
        await this.connect();
    }

    // Establish WebSocket connection
    private async connect(): Promise<boolean> {
        this.disconnect();  // Ensure any existing connection is closed
        this.socket = new WebSocket(this.getWebsocketHost());

        // Add WebSocket event listeners
        this.event.addEvent(this.socket, "open", () => this.onOpen());
        this.event.addEvent(this.socket, "close", () => this.onClose());
        this.event.addEvent(this.socket, "error", (e: any) => this.onError(e));
        this.event.addEvent(this.socket, "message", (msg: MessageEvent) => this.onMessage(msg));

        return new Promise(resolve => {
            const checkConnection = () => {
                setTimeout(() => {
                    console.log("Checking connection");

                    switch (this.socket?.readyState) {
                        case WebSocket.CONNECTING:
                            console.log("connecting");
                            return checkConnection();
                        case WebSocket.OPEN:
                            console.log("connected");
                            return resolve(true);
                        case WebSocket.CLOSING:
                        case WebSocket.CLOSED:
                            console.log("closing");
                            return resolve(false);
                        default:
                            console.log("undefined");
                            resolve(false);
                            this.socket = null;
                    }
                }, 500);
            };
            checkConnection();
        });
    }

    // Handle reconnection attempts
    public async reconnection(): Promise<void> {
        if (this.isRetrying) return;
        this.isRetrying = true;

        const tryReconnect = async () => {
            if (this.retryNo >= this.maxRetry) {
                console.error(`Could not connect to ws, max retry exceeded: ${this.maxRetry}`);
                this.isRetrying = false;
                this.retryNo = 0;
                return;
            }

            if (!await this.connect()) {
                this.retryNo++;
                if (this.timeout !== null) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }

                this.timeout = setTimeout(tryReconnect, 2000);
            } else {
                this.retryNo = 0;
                this.isRetrying = false;
            }
        };

        await tryReconnect();
    }

    // WebSocket event handlers
    private onOpen(): void {
        this.setConnectionStatus(true);
        console.log("Connection established");
    }

    public async onClose(): Promise<void> {
        this.socket = null;
        this.setConnectionStatus(false);
        console.log("Websocket broke");
        await this.reconnection();
        //this.router.goToLostNetwork();
    }

    private async onError(e: any): Promise<void> {
        this.socket = null;
        this.setConnectionStatus(false);
        console.error("Websocket broke, connection error:", e);
        await this.reconnection();
        //this.router.goToLostNetwork();
    }

    private onMessage(msg: MessageEvent): void {
        const struct = JSON.parse(msg.data);
        const message: WebSocketMessage = {
            id: struct.message_id,
            type: struct.type,
            content: struct.answer,
            language: struct.language,
            audioAnswer: struct?.voice,
            attachments: struct?.attachments,
            questionList: struct?.questionList,
            token: this.config.getToken(),
            device: this.config.getDevice()
    };

        if (message.type === "error") {
            message.content = struct.error;
            message.language = "en-US";
        }

        switch (message.type) {
            case "health_check":
                this.sendMessage({ type: "health_check", content: "", language: "", token: "" });
                break;
            default:
                if (!this.visibility.isShown("avatar-conversation")) {
                    this.visibility.showComponent("avatar-conversation");
                }

                this.message.next(message);
                break;
        }
    }

    // Send messages via WebSocket
    public sendMessage(message: AiSendMessage): boolean {
        console.log("Sending message through ws:", message)

        if (!this.getConnectionStatus()) return false;
        this.socket?.send(JSON.stringify(message));
        return true;
    }

    public sendFeedback(feedback: WebSocketFeedback): boolean {
        if (!this.getConnectionStatus()) return false;
        this.socket?.send(JSON.stringify(feedback));
        return true;
    }

    // Disconnect WebSocket
    public disconnect(): void {
        if (this.socket) {
            this.event.removeEvent(this.socket, "open", () => this.onOpen());
            this.event.removeEvent(this.socket, "close", () => this.onClose());
            this.event.removeEvent(this.socket, "error", (e: any) => this.onError(e));
            this.event.removeEvent(this.socket, "message", (msg: MessageEvent) => this.onMessage(msg));
            this.socket.close();
        }
        this.socket = null;
    }

    simulateDisconnect() {
        if (this.socket) {
            this.socket.close(1000, 'Simulated disconnect');
        }
    }

    // Simulate network error
    simulateError() {
        if (this.socket) {
            this.socket.dispatchEvent(new Event('error'));
        }
    }
}
