import { Component } from '@angular/core';

@Component({
    selector: 'app-download',
    template: `
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1886_617)">
                <path d="M12.875 11.6299H10.5C10.1548 11.6299 9.875 11.3501 9.875 11.0049C9.875 10.6597 10.1548 10.3799 10.5 10.3799H12.875C13.9089 10.3799 14.75 9.53876 14.75 8.50488C14.75 7.43595 13.9977 6.62988 13 6.62988C12.6548 6.62988 12.375 6.35007 12.375 6.00488C12.375 4.97101 11.5339 4.12988 10.5 4.12988C10.2973 4.12988 10.0906 4.16891 9.86794 4.2492C9.61731 4.3396 9.33703 4.26123 9.16966 4.05398C8.567 3.30785 7.68506 2.87988 6.75 2.87988C5.02684 2.87988 3.625 4.28176 3.625 6.00488C3.625 6.35007 3.34519 6.62988 3 6.62988C2.00234 6.62988 1.25 7.43595 1.25 8.50488C1.25 9.53876 2.09113 10.3799 3.125 10.3799H5.5C5.84519 10.3799 6.125 10.6597 6.125 11.0049C6.125 11.3501 5.84519 11.6299 5.5 11.6299H3.125C1.40184 11.6299 0 10.228 0 8.50488C0 7.67026 0.297375 6.89123 0.837281 6.31123C1.26216 5.85485 1.80772 5.55426 2.41178 5.43623C2.69147 3.29163 4.53038 1.62988 6.75 1.62988C7.92488 1.62988 9.03728 2.10698 9.86094 2.95179C10.0764 2.90398 10.2901 2.87988 10.5 2.87988C12.0279 2.87988 13.3033 3.98213 13.5726 5.43326C14.1829 5.54913 14.7342 5.85095 15.1627 6.31126C15.7026 6.89123 16 7.67026 16 8.50488C16 10.228 14.5982 11.6299 12.875 11.6299Z" fill="#31405A"/>
                <path d="M12.875 11.6299H10.5C10.1548 11.6299 9.875 11.3501 9.875 11.0049C9.875 10.6597 10.1548 10.3799 10.5 10.3799H12.875C13.9089 10.3799 14.75 9.53876 14.75 8.50488C14.75 7.43595 13.9977 6.62988 13 6.62988C12.6548 6.62988 12.375 6.35007 12.375 6.00488C12.375 4.97101 11.5339 4.12988 10.5 4.12988C10.2973 4.12988 10.0906 4.16891 9.86794 4.2492C9.61731 4.3396 9.33703 4.26123 9.16966 4.05398C8.567 3.30785 7.68506 2.87988 6.75 2.87988C5.02684 2.87988 3.625 4.28176 3.625 6.00488C3.625 6.35007 3.34519 6.62988 3 6.62988C2.00234 6.62988 1.25 7.43595 1.25 8.50488C1.25 9.53876 2.09113 10.3799 3.125 10.3799H5.5C5.84519 10.3799 6.125 10.6597 6.125 11.0049C6.125 11.3501 5.84519 11.6299 5.5 11.6299H3.125C1.40184 11.6299 0 10.228 0 8.50488C0 7.67026 0.297375 6.89123 0.837281 6.31123C1.26216 5.85485 1.80772 5.55426 2.41178 5.43623C2.69147 3.29163 4.53038 1.62988 6.75 1.62988C7.92488 1.62988 9.03728 2.10698 9.86094 2.95179C10.0764 2.90398 10.2901 2.87988 10.5 2.87988C12.0279 2.87988 13.3033 3.98213 13.5726 5.43326C14.1829 5.54913 14.7342 5.85095 15.1627 6.31126C15.7026 6.89123 16 7.67026 16 8.50488C16 10.228 14.5982 11.6299 12.875 11.6299Z" fill="#31405A"/>
                <path d="M10.3169 12.6879C10.0728 12.4439 9.67711 12.4439 9.43304 12.6879L8.62498 13.496V7.25488C8.62498 6.9097 8.34517 6.62988 7.99998 6.62988C7.65479 6.62988 7.37498 6.9097 7.37498 7.25488V13.496L6.56695 12.6879C6.32286 12.4439 5.92714 12.4439 5.68307 12.6879C5.43901 12.932 5.43895 13.3278 5.68304 13.5718L7.11607 15.0049C7.35557 15.2444 7.66886 15.3659 7.98342 15.37C7.98898 15.3702 7.99439 15.3709 8.00001 15.3709C8.00564 15.3709 8.01104 15.3702 8.01661 15.37C8.33117 15.3659 8.64445 15.2444 8.88395 15.0049L10.317 13.5718C10.561 13.3278 10.561 12.932 10.3169 12.6879Z" fill="#31405A"/>
            </g>
            <defs>
                <clipPath id="clip0_1886_617">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    `,
})
export class DownloadComponent {

}
